import React from 'react';
import Link from 'next/link';

import styles from './styles/Splash.module.scss';

export default function Splash() {
  return (
    <div>
      <style jsx>{`
        .splash-height {
          min-height: 60vh;
        }
      `}</style>
      <div className="flex flex-col justify-center items-center splash-height text-center md:mb-32">
        <h1 className={styles.bannerText}>Luxurious & Sustainable</h1>
        <h2 className={styles.bannerTagline}>
          A fresh approach to flower design and <br />
          flower delivery in British Columbia
        </h2>
        <Link className="button primary" href="/shop">Send a Bouquet</Link>
      </div>
    </div>
  )
}