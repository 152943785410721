import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {format} from 'date-fns';

import styles from './styles/ContentCard.module.scss';

export default function ContentCard({imgSrcSet, link, publishedDate, children, alt='', authorName = '', }) {

  const contentCardImageHeight = 200;
  const contentCardImageWidth = 385;

  return (
    <>
      <style>{`
          .contentCardHeight {
            height: ${contentCardImageHeight}px;
          }
          
          .contentCardWidth {
            width: ${contentCardImageWidth}px;
          }
          
          .contentBody {
            height: 75px;
          }
        `}
      </style>
      <div className={`${styles.contentCard} contentCardWidth flex-1`}>
        <div className="relative contentCardHeight">
          <div className="absolute left-0 top-0 z-0 w-full h-full">
            <Link href={link}>
              <Image src={imgSrcSet} alt={alt} height={contentCardImageHeight} width={contentCardImageWidth} />
            </Link>
          </div>
          <div className="absolute z-10 bottom-0 left-0 h-1/4 w-full">
            <Link href={link}>
              <Image src="/images/card-arch.svg" alt="Rounded image bottom layout element" layout='fill' objectFit="cover" />
            </Link>
          </div>
        </div>
        <div className="pb-4 pr-4 pl-4">
          <div className="contentBody">{children}</div>
          <div className="grid grid-cols-2 con">
            <div className="montserrat text-gray-500 text-sm">{ authorName }</div>
            <div className="montserrat text-gray-500 text-sm text-right">{ publishedDate && format(new Date(publishedDate), 'MMMM d, yyyy')}</div>
          </div>
        </div>
      </div>
    </>
  )
}