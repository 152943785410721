import React from 'react';
import styles from './styles/Goldrings.module.scss';

export default function GoldRings() {
  return (
    <div className="container mx-auto grid xl:grid-cols-3 text-center mb-12">
      <div className={`${styles.goldRings} ${styles.goldRings1} flex flex-col justify-center`}>
        <h4>Better Value</h4>
        <p className="w-1/2 mx-auto">Ordering direct cuts out the middle man and means you get what you pay for. Bigger bouquets and better quality in every order.</p>
      </div>
      <div className={`${styles.goldRings} ${styles.goldRings2} flex flex-col justify-center`}>
        <h4>Photo Included</h4>
        <p className="w-1/2 mx-auto">Before we ship, we send you a picture of your order, so that you know exactly what
          you&#39;re getting.</p>
      </div>
      <div className={`${styles.goldRings} ${styles.goldRings3} flex flex-col justify-center`}>
        <h4>Responsible Packaging</h4>
        <p className="w-1/2 mx-auto">Our "local first" bouquets and zero plastics packaging reduces waste and stays out of the landfill.</p>
      </div>
    </div>
  )
};