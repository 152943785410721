import React, {useRef, useEffect, useState} from 'react';
import Image from 'next/image';
import { redirectToCDN } from "../utils/urlHandlers";

import styles from './styles/ImagePanel.module.scss';

export default function ImagePanel({children, src, alt, height=null, minHeight=null, archOverlay=false, imageFilter = "", backgroundColor= "initial", backgroundOpacity = 0, fullHeightWrapper= false, priority="false", className='', ...props}) {

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({});

  let movementTimer = null

  const RESET_TIMEOUT = 100;

  const calculateHeight = (height, minHeight, DOMHeight) => {

    // Return height if a fixed height is set and no minHeight is set. Height overrides DOMHeight. Return height if it's larger than the minHeight
    if(height && !minHeight || height > minHeight) {
      return height;
    }

    if(DOMHeight > minHeight) {
      return DOMHeight;
    }

    // Return minHeight if it's set, and if it's larger than DOMHeight and larger than height
    if(minHeight && minHeight > DOMHeight && minHeight > height){
      return minHeight;
    }

    // Return DOMHeight as the default
    return DOMHeight;
  }

  const getDomHeight = () => {
    if(targetRef.current) {
      setDimensions({
        height: targetRef.current.offsetHeight
      })
    }
  }

  useEffect(() => {
    if(typeof window !== "undefined") {
      window.addEventListener('resize', () => {
        clearInterval(movementTimer);
        movementTimer = setTimeout(getDomHeight, RESET_TIMEOUT);
      });
    }

    getDomHeight();
  }, []);

  const imageFilters = {
    'dimmed': 'dimmedOverlay',
    'bright': 'brightOverlay',
    'spotify': 'gradientOverlay',
    'color': 'colorOverlay'
  };

  const selectedFilter = imageFilters[imageFilter];

  const finalHeight = calculateHeight(height, minHeight, dimensions.height);

  const colorBackgroundStyle = {
    backgroundColor,
    opacity: backgroundOpacity
  }

  return (
    <>
      <style jsx>{`
        .imageBannerHeight {
          height: ${finalHeight}px;
        }
      `}</style>

      <div className={`imageBannerHeight relative ${className}`} {...props}>

        <Image
          fill
          className={styles.imagePanelImage}
          src={redirectToCDN(src)}
          alt={alt}
          priority={priority}
        />

        {selectedFilter === 'colorOverlay' ? (

          <div className={`absolute h-full w-full z-10 ${selectedFilter}`} style={colorBackgroundStyle} />
        ) : (
          <div className={`absolute h-full w-full z-10 ${selectedFilter !== undefined ? selectedFilter : ''}`} />
        )}
        <div className={`absolute h-full w-full z-20 ${archOverlay ? 'archOverlay' : ''}`} />
        <div className="absolute h-full w-full z-30">
          <div ref={targetRef} className={height || fullHeightWrapper ? 'h-full' : '' }>{children}</div>
        </div>
      </div>
    </>
  )
}