import React from 'react';
import Link from 'next/link';
import ImagePanel from "../Shared/ImagePanel";
import styles from './styles/WeddingPanel.module.scss';

export default function WeddingPanel({src, height, width, alt}) {

  return (
    <div className={styles.weddingPanel}>
      <ImagePanel
        src={src}
        height={height}
        width={width}
        alt={alt}
      >
        <div className="gradientOverlay flex flex-col place-content-center items-center h-full text-white">
          <div className="text-center">
            <h2 className="bannerText -mb-8">Wedding Flower Design</h2>
            <h3 className="mb-12">capture your unique style</h3>
            <Link className="button primary" href="/weddings">Discover Your Style</Link>
          </div>
        </div>
      </ImagePanel>
    </div>
  )
}