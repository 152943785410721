import React from 'react';
import ContentCard from "../Shared/ContentCard";
import {redirectToCDN} from "../utils/urlHandlers";
import Link from "next/link";
import {formatFullname} from "../utils/formatters";

export default function LatestContent({blogs}) {

  const blogContent = blogs.map(blog => {
    return (
      <div key={blog.uuid} className="w-5/6 md:w-full mx-auto">
        <ContentCard
          imgSrcSet={redirectToCDN(blog.thumbnail.derivatives[0].url)}
          alt={blog.thumbnail.alt}
          link={blog.url}
          publishedDate={blog.created}
          authorName={formatFullname(blog.author.firstName, blog.author.lastName)}
        >
          <Link className="text-center" href={blog.url}><h2>{blog.title}</h2></Link>
        </ContentCard>
      </div>
    )
  });

  return (
    <div className="container mx-auto pb-12">
      <div className="mb-4 mt-12 grid md:grid-rows-2 md:grid-cols-2 lg:grid-rows-1 xl:grid-cols-4 gap-4">
        {blogContent}
      </div>
      <div className="text-right"><Link href="/blog">Read All Blogs &raquo;</Link></div>
    </div>
  )
}
