import React from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import {redirectToCDN} from "../utils/urlHandlers";
import styles from './styles/FeaturedContent.module.scss';

export default function FeaturedContent({featureCards}) {

  const cards = [...featureCards];
  const firstFeatureCard = cards.shift();

  const getTreatmentClass = function(treatment) {
    const treatments = {
      'left-align': 'text-left',
      'right-align': 'text-right'
    }

    return treatments[treatment] !== undefined ? treatments[treatment] : 'text-center'
  }

  const colHeight = (
    <style>{`
      .colHeight {
        height: 344px;
      }
    `}</style>
  );

  const renderCardContent = (title, body, treatment, linkUrl, linkText) => {

    return (
      <div className={`${treatment} p-5`}>
        <h3>{title}</h3>
        {body && (<div dangerouslySetInnerHTML={{__html: body}} />)}
        {linkUrl && linkText && (
          <Link className="button primary" href={linkUrl}>{linkText}</Link>
        )}
      </div>
    )

  }
  const renderLargeCard = function (card) {

    const largeCardImage = card.cardImage.derivatives.find(derivative => derivative.label === 'feature_card_large');
    const cardImage = card.cardImage.derivatives.find(derivative => derivative.label === 'feature_card');

    const treatment = getTreatmentClass(card.treatment);

    return (
      <>
        {colHeight}
        <div className="row-span-2 relative hidden xl:block">
          <Image alt={card.cardImage.alt} src={redirectToCDN(largeCardImage.url)} objectFit="cover" layout='fill'/>
          <div className="absolute top-0 left-0 h-full w-full flex flex-col gradientOverlay place-content-center">
            {renderCardContent(card.title, card.body, treatment, card.linkUrl, card.linkText)}
          </div>
        </div>
        <div className="relative col-span-2 hidden md:block xl:hidden">
          <Image alt={card.cardImage.alt} src={redirectToCDN(cardImage.url)} layout="fill" objectFit="cover"/>
          <div className="absolute top-0 left-0 h-full w-full flex flex-col gradientOverlay place-content-center">
            {renderCardContent(card.title, card.body, treatment, card.linkUrl, card.linkText)}
          </div>
        </div>
        <div className="relative colHeight md:hidden">
          <Image alt={card.cardImage.alt} src={redirectToCDN(cardImage.url)} layout="fill" objectFit="cover"/>
          <div className="absolute top-0 left-0 h-full w-full flex flex-col gradientOverlay place-content-center">
            {renderCardContent(card.title, card.body, treatment, card.linkUrl, card.linkText)}
          </div>
        </div>
      </>
    )
  }

  const renderCard = function (card) {

    const cardImage = card.cardImage.derivatives.find(derivative => derivative.label === 'feature_card');
    const treatment = getTreatmentClass(card.treatment);

    return (
      <div key={card.uuid} className="relative colHeight">
        <Image alt={card.cardImage.alt} src={redirectToCDN(cardImage.url)} layout="fill" objectFit="cover"/>
        <div className="absolute top-0 left-0 h-full w-full flex flex-col gradientOverlay place-content-center">
          {renderCardContent(card.title, card.body, treatment, card.linkUrl, card.linkText)}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.featuredContent}>
      <div className="container mx-auto text-white grid md:grid-rows-3 md:grid-cols-2 xl:grid-rows-2 xl:grid-cols-3 gap-8">
        {renderLargeCard(firstFeatureCard)}

        {cards.map(card => renderCard(card))}

      </div>
    </div>
  )
}
