import React from 'react';
import Image from 'next/legacy/image';

import styles from './styles/Awards.module.scss';

export default function Awards() {
  return (
    <div className={`${styles.awards} text-center py-8`}>
      <style jsx>{`
        .awardHeader {
          min-height: 3em;
        }
      `}</style>
      <h2>Award Winning Design</h2>
      <h3 className="subcopy">We are honoured and humbled by the recognition from our professional community.</h3>
      <div className="container mx-auto mt-12 mb-4 grid md:grid-cols-2 xl:grid-cols-5 gap-4">
        <div>
          <h6 className="awardHeader">VIWIA Winner 2018 Best Overall Florist</h6>
          <Image alt="VIWIA Winner 2018 Best Overall Florist" src="/images/awards/WINNER2018.png" height={200} width={200} />
        </div>
        <div>
          <h6 className="awardHeader">VIWIA Winner 2019 Best Wedding Florals</h6>
          <Image alt="VIWIA Winner 2019 Best Wedding Florals" src="/images/awards/VancouverIslandAwards_Logo Originalwinner2019.png" height={200} width={200} />
        </div>
        <div>
          <h6 className="awardHeader">BCWA Winner 2020 Best Bridal Bouquet</h6>
          <Image alt="BCWA Winner 2020 Best Bridal Bouquet" src="/images/awards/2020WinnerWebBlack.png" height={195} width={250} />
        </div>
        <div>
          <h6 className="awardHeader">BCWA Winner 2020 Best Wedding Florist - Overall</h6>
          <Image alt="BCWA Winner 2020 Best Wedding Florist - Overall" src="/images/awards/2020WinnerWebBlack.png" height={195} width={250} />
        </div>
        <div>
          <h6 className="awardHeader">CEW Winner 2021 Most Creative Floral Design</h6>
          <Image alt="CEW Winner 2021 Most Creative Floral Design" src="/images/awards/Canadian-Event-Awards-WINNER-2Badge-2021.png" height={250} width={250} />
        </div>
      </div>
    </div>
  );
}